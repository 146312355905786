var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "survey-opened", attrs: { href: _vm.data.link } },
    [
      _c("span", {
        staticClass: "opened",
        domProps: { textContent: _vm._s(_vm.data.opened) },
      }),
      _c("span", {
        staticClass: "sent",
        domProps: { textContent: _vm._s("/" + _vm.data.sent) },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }